interface RouteProps {
  list: string
  add: string
  detail: string
}
function generateRoutes(module): RouteProps {
  return {
    list: '/view/' + module,
    add: '/view/' + module + '/add',
    detail: '/view/' + module + '/detail/:id',
  }
}

export const APP_ROUTES = {
  assessment: generateRoutes('assessments'),
  massTorts: generateRoutes('mass-torts'),
  ot: generateRoutes('ot'),
  medicalRecord: generateRoutes('clinical-records'),
  supplementary: generateRoutes('supplementaries'),
  fileReview: generateRoutes('file-reviews'),
  caseManager: generateRoutes('case-managers'),
  company: generateRoutes('companies'),
  centre: generateRoutes('centres'),
  client: generateRoutes('clients'),
  contractor: generateRoutes('contractors'),
  specialist: generateRoutes('specialists'),
  user: generateRoutes('users'),
  userGroup: generateRoutes('user-group'),
  staff: generateRoutes('staffs'),
  medNeg: generateRoutes('mednegs'),
  co: generateRoutes('co-teleconference'),
  invoice: generateRoutes('invoices'),
  tenant: generateRoutes('tenants'),
  roles: generateRoutes('roles'),
  fundingProvider: generateRoutes('funding-providers'),
  fundingRequest: generateRoutes('facility-requests'),
  fundingAgreement: generateRoutes('facility-agreements'),
  fundingTransaction: generateRoutes('facility-transactions'),
  transactionInvoice: generateRoutes('transaction-invoice'),
  disbursementFunding: generateRoutes('disbursement-funding'),
  marketingCampaign: generateRoutes('marketing-campaigns'),
  checkList: generateRoutes('check-lists'),
  saleTarget: generateRoutes('sale-targets'),
  serviceItem: generateRoutes('service-items'),
  ndisServiceItem: generateRoutes('ndis-service-items'),
  mailTemplate: generateRoutes('mail-templates'),
  imsApproval: generateRoutes('ims-approvals'),
  amaServiceFee: generateRoutes('ama-service-fee'),
  timeTracking: generateRoutes('time-tracking'),
  systemConfig: generateRoutes('system-config'),
  ndis: generateRoutes('ndis'),
  clioApp: generateRoutes('clio-app'),
  countries: generateRoutes('countries'),
  cities: generateRoutes('cities'),
  monthlyActiveAccounts: generateRoutes('monthly-active-accounts'),
  billingSettingList: generateRoutes('billing-setting'),
  attachmentTypes: generateRoutes('attachment-types'),
  languages: generateRoutes('languages'),
  systemUpdate: generateRoutes('system-update'),
  tenantInvoice: generateRoutes('tenant-invoice'),
  billingSetting: generateRoutes('billing-setting'),
  editions: generateRoutes('editions'),
  mvaClaim: generateRoutes('mva-claim'),
  talkingPoint: generateRoutes('talking-point'),
  talkingPointCategory: generateRoutes('talking-point-category'),
  serviceProvider: generateRoutes('service-provider'),
  videoMeeting: generateRoutes('video-meeting'),
  tpd: generateRoutes('tpd'),
}
