import { Avatar, Button, Dropdown, Menu } from 'antd'
import { UserOutlined, MoreOutlined } from '@ant-design/icons'
import localAccountManager, { LocalAccount } from '@services/localAccountManager'
import { useMemo } from 'react'
import * as authService from '@services/oauthService'
import { observer } from 'mobx-react'
import UserProfileImage from '@assets/images/user-profile.png'
import appStore from '@stores/appStore'

const AccountSwitcher = () => {
  const currentAccount = localAccountManager.currentAccount

  const otherAccounts = useMemo(() => {
    return localAccountManager.accounts.filter(
      i => i.tenantId + i.userName != currentAccount?.tenantId + currentAccount?.userName,
    )
  }, [localAccountManager.accounts])

  const handleSignOut = () => {
    localAccountManager.removeCurrentAccount()
    authService.logout()
  }

  const handleAddAnotherAccount = () => {
    authService.logout()
  }

  const handleSwitchTenant = (account: LocalAccount, e) => {
    e?.preventDefault?.()
    e.domEvent.stopPropagation()
    localAccountManager.switchAccount(account)
    location.href = location.origin
  }

  const handleRemoveAccount = (account: LocalAccount, e) => {
    e?.preventDefault?.()
    e.domEvent.stopPropagation()
    localAccountManager.removeAccount(account.tenantId, account.userName)
  }

  const getAccountFullName = account => {
    const userProfile = JSON.parse(account?.oidcUser ?? '{}').profile

    if (!userProfile) return ''
    const fullName = `${userProfile?.given_name ?? ''} ${userProfile?.family_name ?? ''}`.trim()
    return fullName
  }

  const RenderTenantName = ({ text }) => {
    return (
      <div>
        <span className="text-sm">{text || 'Master Tenant'}</span>
      </div>
    )
  }

  const getProfileImageUrl = filename => {
    return filename ? `${process.env.VITE_API_URL}/files/${filename}` : ''
  }

  const profileUrl = appStore.currentUser.extraProperties.ProfileImage
  return (
    <div className="mr-2">
      <Dropdown
        menu={{
          items: [
            {
              key: 'accountMenu',
              label: (
                <div className="min-w-[320px] max-w-[400px] w-auto">
                  <div className="flex justify-between p-2">
                    <div></div>
                    <Button type="text" onClick={handleSignOut}>
                      Sign out
                    </Button>
                  </div>
                  <div className="px-4 pb-4">
                    <div className="flex items-center">
                      <div className="w-20 h-20 mr-2 flex items-center justify-center">
                        {profileUrl ? (
                          <Avatar alt="Profile" shape="circle" size={50} src={getProfileImageUrl(profileUrl)} />
                        ) : (
                          <img src={UserProfileImage} alt="Profile" className="w-20 h-20 rounded-full mr-2" />
                        )}
                      </div>
                      <div>
                        <p className="text-sm font-medium">{getAccountFullName(currentAccount)}</p>
                        <p className="text-sm break-all">{currentAccount?.email}</p>
                        <RenderTenantName text={currentAccount?.tenantName} />
                        <a href="/view/login-account" className="text-sm text-blue-500">
                          My Profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
            ...otherAccounts.map(account => ({
              key: account.tenantId + account.userName,
              label: (
                <div className="flex items-center justify-between break-all">
                  <div className="flex items-center py-1">
                    <UserOutlined className="text-lg rounded-full border-2 p-1 mr-4" />
                    <div>
                      <p className="text-sm font-medium">{getAccountFullName(account)}</p>
                      <p className="text-sm">{account.email}</p>
                      <RenderTenantName text={account.tenantName} />
                    </div>
                  </div>
                  <div>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: 'signout',
                            label: 'Sign out and forget',
                            onClick: info => {
                              handleRemoveAccount(account, info)
                            },
                          },
                        ],
                      }}
                      trigger={['click']}
                    >
                      <MoreOutlined
                        className="text-gray-500 hover:bg-white p-1"
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      />
                    </Dropdown>
                  </div>
                </div>
              ),
              onClick: e => handleSwitchTenant(account, e),
            })),
            {
              key: 'addAccount',
              label: (
                <div className="flex items-center" onClick={handleAddAnotherAccount}>
                  <UserOutlined className="text-lg rounded-full border-2 p-1 mr-4" />
                  <div className="text-sm">Add another account</div>
                </div>
              ),
            },
          ],
        }}
        trigger={['click']}
      >
        <div className="cursor-pointer hover:bg-blue-500 p-2" title="">
          <UserOutlined className="text-xl text-white rounded-full border-2 p-1" />
        </div>
      </Dropdown>
    </div>
  )
}

export default observer(AccountSwitcher)
