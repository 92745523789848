import { ApiRequest } from '../baseService'

const BaseRoute = 'app/system-config'

function GetSystemConfig(tenantId?: string) {
  return ApiRequest.get(`${BaseRoute}${tenantId ? '?tenantId=' + tenantId : ''}`)
}

function UpdateSystemConfig(payload, tenantId?) {
  return ApiRequest.put(`${BaseRoute}${tenantId ? '?tenantId=' + tenantId : ''}`, payload)
}

function GetSetting(key = '') {
  return ApiRequest.get(`${BaseRoute}/getSetting?key=${key}`)
}

function GetSystemConfigForUser(key?: string) {
  return ApiRequest.get(`${BaseRoute}/user?${key ? `&key=${key}` : ''}`)
}

function UpdateSystemConfigUser(payload) {
  return ApiRequest.put(`${BaseRoute}/user`, payload)
}

function UpdateUserTableSetting(payload) {
  return ApiRequest.put(`${BaseRoute}/updateUserTableSetting`, payload)
}

function GetUserTableSettings() {
  return ApiRequest.get(`${BaseRoute}/userTableSettings`)
}

function RemoveConfigForCurrentUser(key: string) {
  return ApiRequest.delete(`${BaseRoute}/removeCurrentUserSetting?key=${key}`)
}

const API = {
  GetSystemConfig,
  UpdateSystemConfig,
  GetSetting,
  GetSystemConfigForUser,
  UpdateSystemConfigUser,
  UpdateUserTableSetting,
  GetUserTableSettings,
  RemoveConfigForCurrentUser,
}

export default API
