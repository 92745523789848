import { isDataChange } from './../utils/functions'
import LocalStorageManager from '@services/storage/LocalStorageManager'
import { FROM_MODULE_TYPE } from '@utils/constants'
import { APP_ROUTES } from '@utils/routesConfig'
import _ from 'lodash'
import { action, computed, makeAutoObservable } from 'mobx'
import {
  FUNDING_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS,
  CM_NOT_ALLOWED_PATHS,
  INSURER_ROLE_ALLOWED_PATHS,
  LMQ_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS,
  MLP_NOT_ALLOWED_PATHS,
} from 'routes-setting/RoutePermissions'
import Url from 'url-parse'
import appStore from './appStore'
import router from './router'
import { generateUrlFromPath, getPathParams, getRouteByPathname } from 'routes-setting/functions'
import { RouteConfig } from 'routes-setting/interface'
import { DashboardRoutes } from 'routes-setting/dashboardRoute'

type TabInfo = {
  path: string
  absUrl: string
  fromModule?: FROM_MODULE_TYPE
  objectId?: string
  route: string
  visited: boolean
  title: string
  postFix?: string
  onClosed?: (params: any) => null | void
  id: string
  tabName: string
  search: string
}

const isSpecialMouseEvent = e => {
  const button = e.buttons || e.button || e.which
  return button !== 1 || e.ctrlKey || e.shiftKey || e.altKey || e.metaKey
}

class DashboardStore {
  constructor() {
    makeAutoObservable(this, {
      currentTab: computed,
    })

    const sidebarCollapsed = LocalStorageManager.localSetting.sidebarCollapsed
    if (sidebarCollapsed) {
      this.sidebar.collapsed = sidebarCollapsed
    }
  }

  updateMaxTabs = () => {
    setTimeout(
      action(() => {
        const tab = document.querySelector(this.tabSelector)
        const tabs = document.querySelector(this.tabsSelector)
        if (!tab || !tabs) {
          return
        }

        this.tabWidth = tab.clientWidth
        this.maxTabs = Math.floor(tabs.clientWidth / this.tabWidth) || 1
        // Clear the registered interval from initializing
        if (this.updateMaxTabsIntervalId) {
          clearInterval(this.updateMaxTabsIntervalId)
          this.updateMaxTabsIntervalId = 0
        }
      }),
      200,
    )
  }
  // State to render tabs on top with a dropdown for overflow tabs

  refreshKey = 0
  tabs: TabInfo[] = this.getSessionTabs()
  maxTabs = 10

  currentTabId = null

  get currentTab() {
    return this.tabs.find(i => i.id === this.currentTabId)
  }
  tabDropdownActive = false
  loading = false

  // State to handle drag/drop reorder tabs with animation
  tabDragdropState = {
    dragging: false,
    index: -1,
    x: {},
  }

  // State to handle sidebar auto-hide toggle
  sidebar = {
    collapsed: window.innerWidth < 1300,
    visible: false,
  }

  // Data which does not directly effect the rendering
  // To save and keep scroll position when reopen a tab
  prevPath = ''
  savedScroll = {}
  // To save and calculate drag/drop data correctly
  tabWidth = 0
  tabDragdropData = null
  // To calculate the maximum number of tabs via DOM selectors before they get overflow
  tabSelector = ''
  tabsSelector = ''
  // To make sure the max tabs get updated at least once
  updateMaxTabsIntervalId: any = null

  /**
   * Hashmap to store previous tabid of tabId
   */
  previousTabs = {}

  saveSessionTabs() {
    LocalStorageManager.sessionSetting.tabs = _.cloneDeep(this.tabs)
    LocalStorageManager.saveSessionSetting()
  }

  clearTabs() {
    LocalStorageManager.sessionSetting.tabs = []
    LocalStorageManager.saveSessionSetting()
  }

  getSessionTabs() {
    const sessionTabs: TabInfo[] = LocalStorageManager.sessionSetting.tabs
    sessionTabs.forEach(item => {
      item.visited = false
    })
    return sessionTabs
  }

  setActiveTab = (tabId, callback?) => {
    if (tabId == this.currentTabId) return

    this.saveCurrentTab()

    const tab = this.tabs.find(i => i && i.id === tabId)
    const tabIndex = this.tabs.findIndex(i => i.id === tabId)

    if (!tab) return

    tab.visited = true
    this.currentTabId = tabId

    if (tabIndex >= this.maxTabs) {
      const remainTabs = this.tabs.filter(i => i.id != tabId)
      this.tabs = [...remainTabs.slice(0, this.maxTabs - 1), this.tabs[tabIndex], ...remainTabs.slice(this.maxTabs - 1)]
    }

    this.saveSessionTabs()

    this.syncBrowserHistory(tab)

    setTimeout(() => {
      const tab = this.tabs.find(i => i && i.id === tabId)
      if (tab) {
        document.title = `${tab.title}${
          appStore.currentTenant?.name ? ' | ' + appStore.currentTenant?.name : ''
        } | Kawaconn`
      }

      if (callback) {
        callback()
      }
    }, 100)
  }

  didmount = (tabSelector, tabsSelector) => {
    this.tabSelector = tabSelector
    this.tabsSelector = tabsSelector
    // To make sure the max tabs get updated at least once
    this.updateMaxTabsIntervalId = setInterval(this.updateMaxTabs, 500)
    window.addEventListener('resize', this.updateMaxTabs, true)
  }

  unmount = () => {
    // Cleanup possible listeners
    if (this.updateMaxTabsIntervalId) {
      clearInterval(this.updateMaxTabsIntervalId)
      this.updateMaxTabsIntervalId = 0
    }
    window.removeEventListener('resize', this.updateMaxTabs, true)
  }

  // data = null
  // fromModule = null
  // key = null
  listModule = [
    {
      key: FROM_MODULE_TYPE.IME,
      pathname: APP_ROUTES.assessment.detail,
    },
    {
      key: FROM_MODULE_TYPE.SUPP,
      pathname: APP_ROUTES.supplementary.detail,
    },
    {
      key: FROM_MODULE_TYPE.MR,
      pathname: APP_ROUTES.medicalRecord.detail,
    },
    {
      key: FROM_MODULE_TYPE.MN,
      pathname: APP_ROUTES.medNeg.detail,
    },
    {
      key: FROM_MODULE_TYPE.NDIS,
      pathname: APP_ROUTES.ndis.detail,
    },
  ]

  setTabTitle = (tabId, title, postFix = null) => {
    const tab = this.tabs.find(i => i && i.id === tabId)
    if (tab) {
      tab.title = title
      if (postFix) {
        tab.postFix = postFix
      }
      this.tabs = [...this.tabs]

      this.refreshKey++
    }
  }

  qs = params =>
    Object.keys(params)
      .filter(key => !(params[key] === undefined || params[key] === null || params[key] === ''))
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')

  /**
   * Open/close tabs logic
   */
  open = (
    routeConfig,
    {
      tabId = null,
      title = '',
      tabName,
      previousTabId,
      onClosed,
      callback,
      search = '',
      params = null,
      pathParams = {},
    }: {
      tabId?: string
      title?: string
      tabName?: string
      previousTabId?: string
      onClosed?: (session: any) => void
      callback?: () => void
      search?: string
      params?: any
      pathParams?: any
    } = {},
  ) => {
    this.saveCurrentTab()

    let absUrl: string
    let postFix: string

    if (typeof routeConfig === 'object') {
      tabName = routeConfig.tabName
      title = routeConfig.tabTitle

      absUrl = routeConfig.path

      postFix = routeConfig.postFix
      if (!routeConfig.params) {
        routeConfig.params = {}
      }
      let routeParams = { ...routeConfig.params, ...(params ?? {}) }
      !!routeParams.onClosed && delete routeParams.onClosed

      const routeParamsString = this.qs(routeParams)
      absUrl = absUrl + '?' + routeParamsString
    } else {
      absUrl = generateUrlFromPath(pathParams, routeConfig)
      if (params) {
        absUrl = `${absUrl}?${this.qs(params)}`
      }

      if (search) {
        absUrl = `${absUrl}?${search}`
      }
    }

    const url = new Url(absUrl)
    const path = url.pathname

    let existedTabIndex: number = null

    if (tabId) {
      existedTabIndex = this.tabs.findIndex(t => t.id === tabId)
    } else if (tabName) {
      existedTabIndex = this.tabs.findIndex(t => t.tabName === tabName)
    } else {
      existedTabIndex = this.tabs.findIndex(t => t.path === path)
    }

    let tabInfo: TabInfo

    // If the tab is not rendered, insert it to the tabs list
    if (existedTabIndex < 0) {
      const routeSetting = getRouteByPathname(path, DashboardRoutes)

      let objectId = undefined
      if (routeSetting) {
        objectId = getPathParams(url.pathname, routeSetting.path)?.isDataChange
      }

      tabInfo = {
        path,
        fromModule: routeSetting?.fromModule,
        objectId: objectId,
        search: url.search,
        absUrl,
        route: path,
        visited: true,
        title: title,
        postFix,
        onClosed,
        id: new Date().getTime().toString(),
        tabName,
      }

      this.tabs.push(tabInfo)
    } else {
      tabInfo = this.tabs[existedTabIndex]
    }

    // Close the sidebar if in auto hide mode
    if (this.sidebar.collapsed && this.sidebar.visible) {
      this.toggleSidebarVisible()
    }

    if (previousTabId) {
      this.previousTabs[tabInfo.id] = previousTabId
    }
    this.prevPath = path

    // Check if the path is not the current pathname in location
    this.setActiveTab(tabInfo.id, callback)

    this.saveSessionTabs()

    appStore.trackPageView(path, tabInfo?.absUrl)
  }

  closeCurrentTab(params?) {
    this.closeTabId(this.currentTabId, params)
  }

  saveCurrentTab() {
    const currentTab = this.tabs.find(i => i.id == this.currentTabId)
    if (!currentTab) return

    if (currentTab.path == window.location.pathname) {
      currentTab.absUrl = window.location.pathname + window.location.search
      currentTab.search = window.location.search
      this.saveSessionTabs()
    }
  }

  updateTabLocation(tabId, location) {
    const tab = this.tabs.find(i => i.id === tabId)
    if (!tab) return

    if (tab.path !== location.pathname) {
      return
    }
    tab.absUrl = location.pathname + location.search
    tab.search = location.search

    this.saveSessionTabs()
  }

  closeTabId(tabId, { useInclude = false, openPreviousTab = false, params = null } = {}) {
    const closeTabIndex = this.tabs.findIndex(i => i.id === tabId)
    if (closeTabIndex < 0) return

    const closeTab = this.tabs[closeTabIndex]

    this.tabs = this.tabs.filter((t, index) => t.id !== tabId)

    this.saveSessionTabs()

    if (openPreviousTab) {
      const previousTabId = this.previousTabs[closeTab.id]
      if (previousTabId) {
        const previousTab = this.tabs.find(i => i.id === previousTabId)
        if (previousTab?.absUrl) {
          this.open(previousTab.absUrl, {
            tabId: previousTab.id,
            callback: () => {
              if (closeTab.onClosed) {
                closeTab.onClosed(params)
              }
            },
          })
        }
        return
      }
    }
    // Open dashboard tab if there's no tab left
    if (this.tabs.length === 0) {
      this.open('/')
      return
      // Open the tab next to the closed one if it's the current tab
    }

    if (closeTab.path === router.location.pathname) {
      const newIndex = Math.min(closeTabIndex, this.tabs.length - 1)
      this.open(this.tabs[newIndex].absUrl)
    }
  }
  close = (
    absUrl,
    {
      onComplete = () => {},
      useInclude = false,
      openPreviousTab = false,
      params = null,
      showWarning = false,
      warningMessage = null,
    } = {},
    id?: any,
  ) => {
    const url = new Url(absUrl)
    const path = url.pathname
    const closeTabIndex = this.tabs.findIndex(t => {
      if (useInclude) {
        return t.path.includes(path)
      }
      return t.path === path
    })

    if (id) {
      this.closeTabId(id, { useInclude, openPreviousTab, params })
      onComplete()
      return
    }

    if (closeTabIndex >= 0) {
      const closeTab = this.tabs[closeTabIndex]
      this.closeTabId(closeTab.id, { useInclude, openPreviousTab, params })
      onComplete()
      return
    }

    onComplete()
  }

  closureCloseTab = (e, absUrl, id?: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.close(absUrl, {}, id)
  }

  closureCloseDropdownTab = (e, absUrl, id?: any) => {
    e.preventDefault()
    e.stopPropagation()
    this.close(absUrl, {}, id)
    // Keep the dropdown visible by not propagate the event to the listener in the window object
  }
  syncBrowserHistory = (tabInfo: TabInfo) => {
    const { pathname, search } = router.location
    if (tabInfo.absUrl !== pathname + search) {
      router.history.push(tabInfo?.absUrl)
    }
  }

  /**
   * Dropdown tabs open/close logic
   */
  openTabDropdown = () => {
    if (this.tabDropdownActive) {
      return
    }
    this.tabDropdownActive = true
    requestAnimationFrame(() => {
      window.removeEventListener('click', this.closeTabDropdown)
      window.addEventListener('click', this.closeTabDropdown)
    })
  }
  closeTabDropdown = () => {
    this.tabDropdownActive = false
    window.removeEventListener('click', this.closeTabDropdown)
  }

  /**
   * Drag/drop reorder tabs logic
   */
  closureOnTabMouseDown = index =>
    action(e => {
      if (isSpecialMouseEvent(e)) {
        return
      }
      // Need to manually close the dropdown tabs
      // Because we prevent default the mouse down event so the click event will never fire
      this.closeTabDropdown()
      //
      e.preventDefault()
      if (this.tabDragdropData) {
        return
      }
      this.cleanupTabDragdropListeners()
      this.registerTabDragdropListeners()
      //
      this.tabDragdropData = {
        index,
        newIndex: index,
        hasMoved: false,
        pageX: e.pageX,
      }
      this.tabDragdropState.dragging = true
      this.tabDragdropState.index = index
    })

  onTabMouseMove = nativeEvent => {
    nativeEvent.preventDefault()
    this.tabDragdropData.hasMoved = true
    //
    const { index } = this.tabDragdropData
    const maxIndex = Math.min(this.maxTabs, this.tabs.length)
    //
    let x = nativeEvent.pageX - this.tabDragdropData.pageX
    const minX = -(index + 0.1) * this.tabWidth
    const maxX = (maxIndex - index - 0.9) * this.tabWidth
    if (x < minX) {
      x = minX
    } else if (x > maxX) {
      x = maxX
    }
    //
    const f = Math.abs(x) / this.tabWidth
    let d = Math.floor(f)
    if (f - d >= 0.5) {
      d += 1
    }
    if (x < 0) {
      d = -d
    }
    //
    let newIndex = index + d
    if (newIndex < 0) {
      newIndex = 0
    } else if (newIndex >= maxIndex) {
      newIndex = maxIndex - 1
    }
    this.tabDragdropData.newIndex = newIndex
    //
    this.tabDragdropState.x = {}
    const fr = newIndex > index ? index + 1 : newIndex
    const to = newIndex > index ? newIndex : index - 1
    const rate = newIndex > index ? -1 : 1
    for (let i = fr; i <= to; i++) {
      this.tabDragdropState.x[i] = rate * this.tabWidth
    }
    this.tabDragdropState.x[index] = x
    // this.pushHistoryIfNotCurrent(this.tabs[index])
  }
  onTabMouseUp = nativeEvent => {
    this.cleanupTabDragdropListeners()
    this.tabDragdropState.index = -1
    if (!this.tabDragdropData.hasMoved) {
      this.endTabDropAnimation()
      return
    }
    requestAnimationFrame(this.startTabDropAnimation)
  }

  startTabDropAnimation = () => {
    const { index, newIndex } = this.tabDragdropData
    this.tabDragdropState.x[index] = (newIndex - index) * this.tabWidth
    setTimeout(this.endTabDropAnimation, 217) // 200ms in Tab.scss + 1 tick
  }

  endTabDropAnimation = () => {
    this.reorderTabs()
    const { newIndex } = this.tabDragdropData
    this.tabDragdropData = null
    this.tabDragdropState.dragging = false
    this.tabDragdropState.index = newIndex
    this.tabDragdropState.x = { index: newIndex }
  }

  reorderTabs = () => {
    if (this.tabs.length === 1) {
      return
    }
    const { index, newIndex } = this.tabDragdropData
    const tab = this.tabs[index]
    const fr = newIndex > index ? this.tabs.slice(0, newIndex + 1).filter(t => t !== tab) : this.tabs.slice(0, newIndex)
    const to = newIndex > index ? this.tabs.slice(newIndex + 1) : this.tabs.slice(newIndex).filter(t => t !== tab)
    this.tabs = [...fr, tab, ...to]
  }
  registerTabDragdropListeners = () => {
    window.addEventListener('mousemove', this.onTabMouseMove, true)
    window.addEventListener('mouseup', this.onTabMouseUp, true)
  }
  cleanupTabDragdropListeners = () => {
    window.removeEventListener('mousemove', this.onTabMouseMove, true)
    window.removeEventListener('mouseup', this.onTabMouseUp, true)
  }

  /**
   * Toggle sidebar logic
   */
  toggleSidebar = () => {
    this.sidebar.collapsed = !this.sidebar.collapsed

    LocalStorageManager.setLocalItem('sidebarCollapsed', this.sidebar.collapsed)

    requestAnimationFrame(this.updateMaxTabs)
  }
  toggleSidebarVisible = () => {
    this.sidebar.visible = !this.sidebar.visible
    const fn = this.sidebar.visible ? 'add' : 'remove'
    document.body.classList[fn]('overflow-hidden')
  }

  isValidRoute = (routeSetting: RouteConfig) => {
    if (!routeSetting) {
      return false
    }
    if (!appStore.authenticated || appStore.isHostTenant) {
      return true
    }

    if (routeSetting.features) {
      const isValidFeature = routeSetting.features.some(featureName => {
        return appStore.currentTenant.features[featureName] === 'True'
      })

      if (!isValidFeature) return false
    }

    if (routeSetting.roles) {
      const roles: string[] = appStore.currentUser.roles
      const isValidRole = routeSetting.roles.some(roleName => {
        return roles.includes(roleName)
      })

      if (!isValidRole) return false
    }

    if (appStore.isInsurerContractor) {
      if (!INSURER_ROLE_ALLOWED_PATHS.includes(routeSetting.path)) {
        return false
      }
    }
    if (appStore.edition.MLP || appStore.edition.LAW_CONNECT) {
      if (MLP_NOT_ALLOWED_PATHS.includes(routeSetting.path)) {
        return false
      }
      if (appStore.isCaseManager) {
        if (CM_NOT_ALLOWED_PATHS.includes(routeSetting.path)) {
          return false
        }
      }
    }

    if (appStore.edition.FUNDING_PROVIDER) {
      if (appStore.isCaseManager) {
        if (CM_NOT_ALLOWED_PATHS.includes(routeSetting.path)) {
          return false
        }
      } else {
        if (!FUNDING_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS.includes(routeSetting.path)) {
          return false
        }
      }
    }

    if (appStore.edition.LMQ_PROVIDER) {
      if (!LMQ_PROVIDER_ADMIN_STAFF_ALLOWED_PATHS.includes(routeSetting.path)) {
        return false
      }
    }

    return true
  }
}

const dashboardStore = new DashboardStore()

export default dashboardStore

const observeUrlChange = () => {
  let oldHref = document.location.href
  const body = document.querySelector('body')
  const observer = new MutationObserver(mutations => {
    mutations.forEach(() => {
      if (oldHref !== document.location.href) {
        oldHref = document.location.href
        dashboardStore.updateTabLocation(dashboardStore.currentTabId, document.location)
      }
    })
  })
  observer.observe(body, { childList: true, subtree: true })
}

window.onload = observeUrlChange
