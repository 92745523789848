import { observer } from 'mobx-react'
import NotificationItem from '../NotificationItem'
import store from '../../store'
import { AppButton } from '@components/AppComponents'

const ContentNotification = () => {
  return (
    <div className="h-full max-h-full overflow-auto" key={store.refreshDropDownContent}>
      <div className="">
        {store.notifications.map(item => (
          <NotificationItem data={item} />
        ))}
      </div>
      {store.notifications.length >= 20 && (
        <AppButton
          onClick={() => {
            store.fetchNotifications({ skip: store.filter.skip + 20 })
          }}
        >
          Load more
        </AppButton>
      )}
    </div>
  )
}

export default observer(ContentNotification)
