import appStore from '@stores/appStore'
import { ApiRequest, queryClient } from '../baseService'

const BaseRoute = 'app/countries'

function GetCountries(isCacheData = true, params = {}) {
  return queryClient.fetchQuery(
    ['getCountries', params],
    () => {
      return ApiRequest.get(`${BaseRoute}`, {
        params: params,
      })
    },
    {
      staleTime: isCacheData ? 300 * 1000 : 0, // 5 minutes
    },
  )
}

function GetCitiesByCountry(countryId, isCacheData = true, params = {}) {
  countryId = countryId ?? appStore.currentTenant.country?.id
  return queryClient.fetchQuery(
    ['getCities', countryId],
    () => {
      return ApiRequest.get(`${BaseRoute}/${countryId}/cities`, { params })
    },
    {
      staleTime: isCacheData ? 300 * 1000 : 0, // 5 minutes
    },
  )
}

function GetPostCodesByCountry(countryName, keyword, excludePostBoxFlag = false) {
  return queryClient.fetchQuery(
    ['getPostCodes', countryName],
    () => {
      return ApiRequest.get(
        `app/postcode?countryName=${countryName}&q=${keyword}&excludePostBoxFlag=${excludePostBoxFlag}`,
      )
    },
    {
      // staleTime: 300 * 1000, // 5 minutes,
    },
  )
}

const API = {
  GetCountries,
  GetCitiesByCountry,
  GetPostCodesByCountry,
}

export default API
