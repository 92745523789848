import { queryClient } from '../baseService'
import { ApiRequest, CreateParams, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/tpd'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const UpdateBookingDetails = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/booking-detail`, data)
}

const SendEmail = (id, data, isSMS = false, isResend = true) => {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}&isSMS=${isSMS}&isResend=${isResend}`)
}

function Rebook(id, notifyCaseDNA = false) {
  queryClient.invalidateQueries([BaseRoute]) // invalid assessment detail
  return ApiRequest.post(`${BaseRoute}/${id}/rebook?notifyCaseDNA=` + notifyCaseDNA)
}

function SendReportStatus(id, payload = {}) {
  let qs = CreateParams(payload)
  return ApiRequest.post(`${BaseRoute}/${id}/sendReportStatus?${qs.join('&')}`)
}

function UpdatePrecedentAuthority(id, data) {
  return ApiRequest.put(`${BaseRoute}/${id}/precedentAuthority`, data)
}

function UpdateCostAgreement(id, data) {
  return ApiRequest.put(`${BaseRoute}/${id}/costAgreement`, data)
}

function GetClaimForms(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/ClaimForms`)
}

function UpdateClaimForm(id, data) {
  return ApiRequest.put(`${BaseRoute}/${id}/ClaimForms`, data)
}

// function RemoveClaimForm(id, claimFormId) {
//   return ApiRequest.delete(`${BaseRoute}/${id}/ClaimForms/${claimFormId}`)
// }

const API = {
  ...baseFunctions,
  UpdateBookingDetails,
  SendEmail,
  Rebook,
  SendReportStatus,
  UpdatePrecedentAuthority,
  UpdateCostAgreement,
  GetClaimForms,
  UpdateClaimForm,
  // RemoveClaimForm,
}
export default API
