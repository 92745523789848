import { ROUTE_PATH } from '@consts/appConsts'
import dashboardStore from '@stores/dashboard'
import { FROM_MODULE_TYPE, IME_SERVICE_TYPE, MN_SERVICE_TYPE, SUPP_SERVICE_TYPE } from '@utils/constants'
import React from 'react'
import cx from 'classnames'
import ClickableText from '@components/ClickableText'

type Props = {
  module: FROM_MODULE_TYPE
  id: string
  serviceType?: number
  text: string
  title?: string
  postFix?: string
  params?: any
  onClick?: () => void
  disable?: boolean
  style?: object
  children?: React.ReactNode
  callbackBeforeOnClick?: () => void
  textColor?: string
  className?: string
  disabledStopPropagation?: boolean
}

const ModuleDetailLink = (props: Props) => {
  const handleClick = e => {
    if (props.disabledStopPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (props.callbackBeforeOnClick) {
      props.callbackBeforeOnClick()
    }

    if (!props.disable) {
      openCaseDetail({ ...props, title: props.title || props.text || '', postFix: props.postFix })
    }
  }
  return (
    <ClickableText onClick={handleClick} readonly={props.disable} title={props.text} className={props.className}>
      {props.children ? props.children : props.text || ''}
    </ClickableText>
  )
}

interface PropsInput {
  id: string
  title: string
  postFix?: string
  onClick?: () => void
  module: FROM_MODULE_TYPE
  serviceType?: number | string
  params?: any
  disable?: boolean
}

export const openCaseDetail = (props: PropsInput) => {
  if (props.disable) {
    return
  }
  if (props.onClick) {
    props.onClick()
    return
  }

  switch (props.module) {
    case FROM_MODULE_TYPE.IME:
    case FROM_MODULE_TYPE.CO_TELECONFERENCE:
      switch (props.serviceType) {
        case IME_SERVICE_TYPE.OT:
          dashboardStore.open(ROUTE_PATH.ot.detail(props.id, props.title, props.postFix), { params: props.params })
          break
        case IME_SERVICE_TYPE.MT:
          dashboardStore.open(ROUTE_PATH.massTorts.detail(props.id, props.title), { params: props.params })
          break
        case IME_SERVICE_TYPE.CO:
          dashboardStore.open(ROUTE_PATH.co.detail(props.id, props.title), { params: props.params })
          break
        default:
          dashboardStore.open(ROUTE_PATH.assessment.detail(props.id, props.title), { params: props.params })
          break
      }
      break
    case FROM_MODULE_TYPE.OT:
      dashboardStore.open(ROUTE_PATH.ot.detail(props.id, props.title, props.postFix), { params: props.params })
      break
    case FROM_MODULE_TYPE.STAFF:
      dashboardStore.close('/view/staffs')
      setTimeout(() => {
        dashboardStore.open(`/view/staffs?id=${props.id}&action=edit`)
      })
      break
    case FROM_MODULE_TYPE.MT:
      dashboardStore.open(ROUTE_PATH.massTorts.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.MEDICAL_TREATMENT_PROVIDER:
      dashboardStore.open(ROUTE_PATH.treatment.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.SUPP:
      if (props.serviceType === SUPP_SERVICE_TYPE.FR) {
        dashboardStore.open(ROUTE_PATH.fileReview.detail(props.id, props.title), { params: props.params })
      } else {
        dashboardStore.open(ROUTE_PATH.supplementary.detail(props.id, props.title), { params: props.params })
      }
      break
    case FROM_MODULE_TYPE.FR:
      dashboardStore.open(ROUTE_PATH.fileReview.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.MR:
      dashboardStore.open(ROUTE_PATH.medicalRecord.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.INVOICE:
      dashboardStore.open(ROUTE_PATH.invoice.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.COMPANY:
      dashboardStore.open(ROUTE_PATH.company.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.CASE_MANAGER:
      dashboardStore.open(ROUTE_PATH.caseManager.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.CLIENT:
      dashboardStore.open(ROUTE_PATH.client.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.CONTRACTOR:
      dashboardStore.open(ROUTE_PATH.contractor.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.SPECIALIST:
      dashboardStore.open(ROUTE_PATH.specialist.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.CENTRE:
      dashboardStore.open(ROUTE_PATH.centre.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.MN:
    case FROM_MODULE_TYPE.SPECIALTY_MN:
      if (props.serviceType === MN_SERVICE_TYPE.CO) {
        dashboardStore.open(ROUTE_PATH.co.detail(props.id, props.title), { params: props.params })
      } else {
        dashboardStore.open(ROUTE_PATH.medneg.detail(props.id, props.title), { params: props.params })
      }
      break
    case FROM_MODULE_TYPE.NDIS:
      dashboardStore.open(ROUTE_PATH.ndis.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.FUNDING_REQUEST:
      dashboardStore.open(ROUTE_PATH.fundingRequest.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.FUNDING_AGREEMENT:
      dashboardStore.open(ROUTE_PATH.fundingAgreement.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.FUNDING_TRANSACTION:
      dashboardStore.open(ROUTE_PATH.fundingTransaction.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.DISBURSEMENT_FUNDING:
      dashboardStore.open(ROUTE_PATH.disbursementFunding.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.REPAYMENT_INVOICE:
      dashboardStore.open(ROUTE_PATH.transactionInvoice.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.SYSTEM_UPDATE:
      dashboardStore.open(ROUTE_PATH.systemUpdate.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.SYSTEM_INVOICE:
      dashboardStore.open(ROUTE_PATH.tenantInvoice.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.MVA:
      dashboardStore.open(ROUTE_PATH.mva.detail(props.id, props.title), { params: props.params })
      break
    case FROM_MODULE_TYPE.SERVICE_PROVIDER:
      dashboardStore.open(ROUTE_PATH.serviceProvider.detail(props.id, props.title), { params: props.params })
    case FROM_MODULE_TYPE.TPD:
      dashboardStore.open(ROUTE_PATH.tpd.detail(props.id, props.title), { params: props.params })
      break
  }
}

export default ModuleDetailLink
