import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo } from 'react'
import notificationService from './realtimeNotificationService'
import { NotificationType, NotificationTypeDisplayName, RealtimeNotificationData } from './types'
import ButtonLink from '@components/ButtonLink'
import store from '../store'
import { notification } from 'antd'
import DisplayText from '@components/DisplayText/DisplayText'
import appStore from '@stores/appStore'

const getNotificationTypeDisplayName = (type: NotificationType) => {
  return NotificationTypeDisplayName[type.toString()] || type.toString()
}

const showPopupForNewItemKey = 'ICS.InAppNotification.ShowPopupForNewItem'
const RealtimeNotification = () => {
  useEffect(() => {
    notificationService.setupRealtimeNotification().then(() => {
      notificationService.onNotification(receiveNotification)
    })
  }, [])

  const showPopup = useMemo(() => {
    const userSetting = appStore.currentUser?.settings?.[showPopupForNewItemKey]?.toLowerCase()
    if (userSetting) {
      return userSetting === 'true'
    } else {
      return appStore.currentTenant.settings?.[showPopupForNewItemKey]?.toLowerCase() === 'true'
    }
  }, [appStore.currentUser, appStore.currentTenant])

  const receiveNotification = useCallback((data: RealtimeNotificationData) => {
    store.refresh()
    if (!showPopup) {
      return
    }
    try {
      notification.info({
        message: getNotificationTypeDisplayName(data.type),
        description: (
          <>
            <div>{data.title}</div>
            <div>
              <DisplayText text={data.content} maxLength={50} isHtmlContent />
            </div>
            {data.reference && (
              <ButtonLink
                onClick={() => {
                  // handle navigate to referenced data
                }}
                className="text-xs"
              >
                View Details
              </ButtonLink>
            )}
          </>
        ),
        duration: 5,
      })
    } catch {}
  }, [])

  return <></>
}

export default observer(RealtimeNotification)
