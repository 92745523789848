import { Button, Card, Dropdown, Switch } from 'antd'
import store from '../../store'
import { NotificationTypeDisplayName } from '@components/Notification/realtime-notification/types'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { FilterOutlined } from '@ant-design/icons'

interface Props {
  onChange: (isOpen: boolean) => void
}

const NotificationTypeFilter = (props: Props) => {
  const [showFilter, setShowFilter] = useState(false)

  const filterTypes = useMemo(() => {
    return store.filterSetting.notificationTypes
  }, [store.filterSetting])

  const [isEnableAll, setIsEnableAll] = useState(false)

  const types = useMemo(() => {
    return Object.keys(NotificationTypeDisplayName)
  }, [])

  useEffect(() => {
    if (filterTypes == null || types.every(type => filterTypes.includes(type))) {
      setIsEnableAll(true)
    } else {
      setIsEnableAll(false)
    }
  }, [filterTypes])

  return (
    <div>
      <Dropdown
        dropdownRender={() => {
          return (
            <Card className="p-2 w-[400px]">
              <div className="flex items-center gap-2 mb-2">
                <Switch
                  checked={isEnableAll}
                  onChange={value => {
                    if (value) {
                      setIsEnableAll(true)
                      store.updateFilterSetting({ notificationTypes: null })
                    } else {
                      setIsEnableAll(false)
                      store.updateFilterSetting({ notificationTypes: [] })
                    }
                  }}
                  size="small"
                />
                <span>All Types</span>
              </div>
              <div className="ml-2">
                {Object.keys(NotificationTypeDisplayName).map((key, index) => {
                  return (
                    <div key={index} className="flex items-center gap-2">
                      <Switch
                        checked={isEnableAll || filterTypes?.includes(key)}
                        onChange={value => {
                          let updateItems = [...(filterTypes ?? [])]
                          if (value) {
                            updateItems.push(key)
                          } else {
                            updateItems = filterTypes?.filter(item => item !== key)
                          }

                          store.updateFilterSetting({ notificationTypes: updateItems })
                        }}
                        size="small"
                      />
                      <span>{NotificationTypeDisplayName[key]}</span>
                    </div>
                  )
                })}
              </div>
            </Card>
          )
        }}
        trigger={['click']}
        open={showFilter}
        onOpenChange={isOpen => {
          setShowFilter(isOpen)
          props.onChange(isOpen)
        }}
      >
        <Button icon={<FilterOutlined />} type="text" title="Filter" />
      </Dropdown>
    </div>
  )
}

export default observer(NotificationTypeFilter)
