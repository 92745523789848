import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/notifications/inApp'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function MarkAsRead(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/markAsRead`)
}

function MarkAsUnread(id) {
  return ApiRequest.put(`${BaseRoute}/${id}/markAsUnread`)
}

function SearchByObjectId(objectId) {
  return ApiRequest.get(`${BaseRoute}/searchByObjectId/${objectId}`)
}

async function GetSummary() {
  await queryClient.invalidateQueries([`${BaseRoute}/summary`])
  return await ApiRequest.get(`${BaseRoute}/summary`)
}

function MarkAllAsRead() {
  return ApiRequest.put(`${BaseRoute}/markAllAsRead`)
}

const API = {
  ...baseFunctions,
  MarkAsRead,
  MarkAsUnread,
  SearchByObjectId,
  GetSummary,
  MarkAllAsRead,
}

export default API
