import { FROM_MODULE_TYPE } from '@utils/constants'
import { ApiRequest, CreateParams, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/supplementary'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function RequestFromModule(params) {
  return ApiRequest.get(`${BaseRoute}/suppFromModule`, { params: params })
}

function CheckExistCaseNo(caseNo, assessmentServiceType = null, suppServiceType = null) {
  if (!caseNo) return null
  caseNo = caseNo?.trim()
  let fromModule = ''
  if (
    caseNo.includes(FROM_MODULE_TYPE.FR) ||
    caseNo.includes('SR') ||
    caseNo.startsWith('F') ||
    caseNo.startsWith('S')
  ) {
    fromModule = FROM_MODULE_TYPE.SUPP
  } else if (
    caseNo.includes(FROM_MODULE_TYPE.OT) ||
    caseNo.includes(FROM_MODULE_TYPE.IME) ||
    caseNo.includes('IM') ||
    caseNo.includes('CT') ||
    caseNo.includes('A')
  ) {
    fromModule = FROM_MODULE_TYPE.IME
  } else {
    fromModule = caseNo.slice(0, caseNo?.indexOf('-'))
  }
  return ApiRequest.get(`${BaseRoute}/checkExistCaseNo`, {
    params: { caseNo, fromModule: fromModule.toUpperCase(), assessmentServiceType, suppServiceType },
  })
}

function SendReportStatus(id, payload = {}) {
  queryClient.invalidateQueries([BaseRoute, id])
  let qs = CreateParams(payload)
  return ApiRequest.post(`${BaseRoute}/${id}/sendReportStatus?${qs.join('&')}`)
}

function Rebook(id) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`${BaseRoute}/${id}/rebook`)
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function SendEmail(id = '', type) {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${type}`)
}

function PrintServiceRequest(id) {
  return ApiRequest.get(`${BaseRoute}/${id}/fileReviewPrint`)
}

const UpdateBookingDetails = data => {
  queryClient.invalidateQueries([BaseRoute, data.id]) // invalid assessment detail
  return ApiRequest.put(`${BaseRoute}/${data.id}/booking-detail`, data)
}

const API = {
  ...baseFunctions,
  RequestFromModule,
  CheckExistCaseNo,
  SendReportStatus,
  Rebook,
  ExportSearchResult,
  SendEmail,
  PrintServiceRequest,
  UpdateBookingDetails,
}

export default API
