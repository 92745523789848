import { Button } from 'antd'
import cx from 'classnames'
import styled from 'styled-components'

const ButtonWrapper = styled(Button)`
  &.text-button {
    padding: 0 !important;
    height: fit-content !important;
    user-select: text !important;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.text-button:hover {
    background-color: inherit !important;
    box-shadow: inherit !important;
  }
`
const ClickableText = ({
  children,
  readonly = false,
  onClick = null,
  title = undefined,
  className = '',
  noWrap = false,
}) => {
  if (readonly) {
    ;<ButtonWrapper type="text" title={title} className={cx('text-button font-medium hover:text-blue-400', className)}>
      <span className="text-truncate">{children}</span>
    </ButtonWrapper>
  }
  return (
    <ButtonWrapper
      type="text"
      onClick={onClick}
      title={title}
      className={cx('text-button font-medium hover:text-blue-400 cursor-pointer', className)}
    >
      <span className={cx('text-truncate', noWrap ? 'whitespace-nowrap' : '')}>{children}</span>
    </ButtonWrapper>
  )
}

export default ClickableText
