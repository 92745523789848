import { tripHtmlFromBody } from '@utils/functions'
import { observer } from 'mobx-react'
import { useMemo } from 'react'

type Props = {
  text: string
  maxLength?: number
  isHtmlContent?: boolean
}

const DisplayText = ({ text, maxLength, isHtmlContent = false }: Props) => {
  const trimText = useMemo(() => {
    if (isHtmlContent) {
      return tripHtmlFromBody(text, maxLength)
    }
    // Trim text by maxLength break by space
    if (maxLength && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`
    }
    return text
  }, [text])
  return <span>{trimText}</span>
}
export default observer(DisplayText)
