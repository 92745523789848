import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/payments'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GetInvoiceFromModuleById(objectId, createInvoiceFrom, serviceType, from, to) {
  return ApiRequest.get(
    `${BaseRoute}/invoiceFromModule?objectId=${objectId}&createInvoiceFrom=${createInvoiceFrom}&serviceType=${serviceType}&taskFrom=${from}&taskTo=${to}`,
  )
}

const GetInvoiceStatusCount = () => {
  return ApiRequest.get(`${BaseRoute}/reportStatus`)
}

const ShouldSendOverdueInvoiceReminder = id => {
  return ApiRequest.get(`${BaseRoute}/shouldSendOverdueInvoiceReminder?id=${id}`)
}

const SendOverdueInvoiceReminder = id => {
  return ApiRequest.get(`${BaseRoute}/sendOverdueInvoiceReminder?id=${id}`)
}

const GetClientPaymentStatusCount = () => {
  return ApiRequest.get(`${BaseRoute}/clientPaymentStatus`)
}

const GetInvoiceProcessedStatusCount = () => {
  return ApiRequest.get(`${BaseRoute}/invoiceProcessedStatus`)
}

const GetSpecialistPaymentStatusCount = () => {
  return ApiRequest.get(`${BaseRoute}/specialistPaymentStatus`)
}

const GetXeroInvoiceCount = () => {
  return ApiRequest.get(`${BaseRoute}/xeroInvoice`)
}

const ExportSearchResult = (params = {}) => {
  return ApiRequest.post(`${BaseRoute}/export`, params, { responseType: 'arraybuffer' })
}
const ExportIcareReport = (params = {}) => {
  return ApiRequest.post(`${BaseRoute}/ExportIcareReport`, params, { responseType: 'arraybuffer' })
}

const UpdatePaymentBlueprint = data => {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put('/app/payment-blueprints', data, {
    params: { objectId: data.objectId, fromModule: data.fromModuleType },
  })
}

const CreatePaymentBlueprint = data => {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`/app/payment-blueprints`, data, {
    params: {
      objectId: data.objectId,
      fromModule: data.fromModuleEnum,
    },
  })
}

const CancelInvoice = data => {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.post(`/app/payments/cancel`, data, {
    params: {
      invoiceId: data.invoiceId,
      cancelComment: data.cancelComment,
    },
  })
}

const ResendEmailInvoiceToCMs = (id, type, email = null) => {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${type}&email=${email}`)
}

const UploadInvoiceToAccounting = (id, type) => {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.post(`${BaseRoute}/${id}/uploadToAccouting?uploadType=${type}`)
}

const PrintInvoice = (id, params?) => {
  return ApiRequest.get(`${BaseRoute}/${id}/print`, {
    params,
  })
}
const OutStandingStatementPrint = (payload = {}) => {
  return ApiRequest.post(`${BaseRoute}/outstanding-statement-print`, payload)
}

const GetPaymentBlueprint = params => {
  return ApiRequest.get(`/app/payment-blueprints/from-module`, { params })
}

const SendPaymentBlueprintEmail = params => {
  return ApiRequest.post(`/app/payment-blueprints/emails`, null, { params })
}

const SendInvoiceEmail = id => {
  return ApiRequest.post(`/app/payments/${id}/emails`)
}

const SendSelectedInvoices = params => {
  return ApiRequest.post(`/app/payments/SendSelectedInvoices`, params)
}
const ExportSelectedInvoices = params => {
  return ApiRequest.post(`/app/payments/ExportSelectedInvoices`, params, { responseType: 'arraybuffer' })
}

const GetInvoiceService = () => {
  return ApiRequest.get(`${BaseRoute}/invoiceService`)
}

const CaseDetailPayments = params => {
  return ApiRequest.get(`app/caseDetailPayments`, { params })
}

function ManualXeroSync(params) {
  return ApiRequest.post('app/xero/manualSync', null, {
    params,
  })
}
function ExportIroInvoice(params) {
  return ApiRequest.post(`app/payments/export-iro`, params, { responseType: 'arraybuffer' })
}

function DownloadIroInvoiceReportFiles(params) {
  return ApiRequest.post(`app/payments/downloadIroReportFiles`, params, { responseType: 'arraybuffer' })
}

function ExportIroInvoice2(params) {
  return ApiRequest.post(`app/payments/export-iro2`, params, { responseType: 'arraybuffer' })
}

const GetServiceItemFees = params => {
  return ApiRequest.get(`/app/payment-blueprints/servicePaymentItemFees`, { params })
}

const UpdateServiceItems = data => {
  return ApiRequest.put(`/app/payment-blueprints/servicePaymentItemFees`, data, {
    params: {
      objectId: data.objectId,
      fromModule: data.fromModuleEnum,
    },
  })
}
const RemovePartialPayments = (paymentId, id) => {
  return ApiRequest.delete(`/app/payments/${paymentId}/partial-payments/${id}`)
}
const RemoveCommissionPayments = (paymentId, id) => {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`/app/payments/${paymentId}/commission-payment/${id}`)
}

const API = {
  ...baseFunctions,
  GetInvoiceStatusCount,
  GetClientPaymentStatusCount,
  GetSpecialistPaymentStatusCount,
  GetXeroInvoiceCount,
  UpdatePaymentBlueprint,
  GetPaymentBlueprint,
  CreatePaymentBlueprint,
  GetInvoiceFromModuleById,
  CancelInvoice,
  PrintInvoice,
  OutStandingStatementPrint,
  ResendEmailInvoiceToCMs,
  SendPaymentBlueprintEmail,
  SendInvoiceEmail,
  ExportIcareReport,
  GetInvoiceService,
  CaseDetailPayments,
  ShouldSendOverdueInvoiceReminder,
  SendOverdueInvoiceReminder,
  GetInvoiceProcessedStatusCount,
  ManualXeroSync,
  UploadInvoiceToAccounting,
  ExportIroInvoice,
  SendSelectedInvoices,
  ExportSelectedInvoices,
  ExportSearchResult,
  UpdateServiceItems,
  GetServiceItemFees,
  DownloadIroInvoiceReportFiles,
  ExportIroInvoice2,
  RemovePartialPayments,
  RemoveCommissionPayments,
}

export default API
