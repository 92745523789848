import { FROM_MODULE_TYPE } from '@utils/constants'

export type ActivityType = 'ITEM_VIEWING' | 'ITEM_EDITING' | 'ITEM_DETAIL_CLOSED'

export interface UserActivity {
  activityType: ActivityType
  createdTime: string
  fromModule: FROM_MODULE_TYPE
  objectId?: string
  tenantId?: string
  userId?: string
  user?: any
}

type NotificationDataReference = {
  fromModule: string
  objectId: string
  displayName?: string
}

export enum NotificationType {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  // ACTION_REQUIRED_UPDATED = 'ACTION_REQUIRED_UPDATED',
  // ACTION_REQUIRED_COMPLETED = 'ACTION_REQUIRED_COMPLETED',
  EMAIL_SENT = 'EMAIL_SENT',
}

export const NotificationTypeDisplayName = {
  [NotificationType.ACTION_REQUIRED]: 'Action Required',
  // [NotificationType.ACTION_REQUIRED_UPDATED]: 'Action Required Updated',
  // [NotificationType.ACTION_REQUIRED_COMPLETED]: 'Action Required Completed',
  [NotificationType.EMAIL_SENT]: 'Email Received',
}

export type RealtimeNotificationData = {
  title: string
  content: string
  type: NotificationType
  createdTime?: string
  sentByUserId?: string
  reference?: NotificationDataReference
}
