import realtimeNotificationService from '@components/Notification/realtime-notification/realtimeNotificationService'
import dashboardStore from '@stores/dashboard'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react'
import { useMemo } from 'react'

// List of predefined colors
const colors = [
  '#FF5733', // Red
  '#33FF57', // Green
  '#5733FF', // Blue
  '#FF33E5', // Pink
  '#33FFFF', // Cyan
  '#FFFF33', // Yellow
  '#FF5733', // Orange
  '#33FF57', // Lime
  '#5733FF', // Purple
  '#FF33E5', // Magenta
]

const images = [
  'border-collie.png',
  'bull.png',
  'butterfly.png',
  'camel.png',
  'cat-butt.png',
  'crab.png',
  'crane-bird.png',
  'kiss-panda.png',
  'orca.png',
  'parrot.png',
  'seagull.png',
]

interface UserColor {
  colorIndex: number
}

let userColors: {
  [userId: string]: {
    color: string
    image: string
  }
} = {}

function getColorByUserId(userId) {
  if (!userColors[userId]) {
    const assignedColors = Object.keys(userColors)

    const availableColors = colors.filter(color => !assignedColors.includes(color))
    if (availableColors.length > 0) {
      const assignColor = availableColors[0]
      userColors[userId] = {
        color: assignColor,
        image: images[colors.indexOf(assignColor)],
      }
    } else {
      userColors[userId] = {
        color: '#000000',
        image: images[0],
      }
    }
  }

  return userColors[userId]
}

const ViewUsers = () => {
  const activityLogs = useMemo(() => {
    if (dashboardStore.currentTab?.fromModule && dashboardStore.currentTab?.objectId) {
      return realtimeNotificationService.getUserActivities(
        dashboardStore.currentTab?.fromModule,
        dashboardStore.currentTab?.objectId,
        'ITEM_EDITING',
      )
    }
    return []
  }, [dashboardStore.currentTab, realtimeNotificationService.activityLogs])

  return (
    <div className="flex items-center gap-1 mr-2">
      {activityLogs.map((log, index) => {
        return (
          <Tooltip title={log.user?.displayName} key={log.userId}>
            <img
              src={'/assets/images/animals/' + getColorByUserId(log.userId).image}
              alt={log.user?.displayName}
              className="w-8 h-8 rounded-full cursor-pointer"
              style={{ border: '2px solid white', padding: '2px', background: `${getColorByUserId(log.userId).color}` }}
            />
          </Tooltip>
        )
      })}
    </div>
  )
}

export default observer(ViewUsers)
