import { ApiService } from '@services/ApiService'
import appStore from '@stores/appStore'
import { downloadFileFromResponse, mapParamsFetchData } from '@utils/functions'
import dayjs from 'dayjs'
import { action, computed, makeObservable, observable } from 'mobx'
import { formatMoney } from './../../../utils/functions'
import { showErrorForDev } from '@utils/errors'

const defaultFilters = {
  includeXeroUploadedInvoiceOnly: false,
  showProviderPayment: localStorage.getItem('showProviderPayment') == 'true',
  specialistId: null,
  requestDateFrom: dayjs().startOf('month').format('YYYY-MM-DD'),
  requestDateTo: dayjs().endOf('month').format('YYYY-MM-DD'),
  monthPeriod: dayjs().add(-1, 'month').format('MMMM'),
  yearPeriod: dayjs().month() === 0 ? dayjs().add(-1, 'year').year().toString() : dayjs().year().toString(),
  skipCount: 0,
  maxResultCount: 20,
  sorting: 'FullName',
  dateTypeToFilter: 1, //InvoiceDate
  actionRequiredDueDateFrom: null,
  actionRequiredDueDateTo: null,
  actionRequiredById: null,
  commissionPaymentStatus: null,
}

class Store {
  static storeName = 'SpecialistRemittanceStore'
  constructor() {
    makeObservable(this, {
      loading: observable,
      data: observable,
      total: observable,
      searchFilters: observable,
      specialistData: observable,
      specialistIds: observable,
      totalRemittanceAmout: computed,
      open: observable,
      modalParams: observable,
      changeFiltersValue: action,
      setFieldsValue: action,
      fetch: action,
      filters: action,
      resetStore: action,
    })
  }

  loading = false
  data = []
  total = 0
  searchFilters = { ...defaultFilters }
  specialistData = []
  specialistIds = []
  open = false
  openModalViewCheckedStatement = false
  modalParams: any = {}
  dataActionPIC = []
  resetStore = () => {
    this.loading = false
    this.data = []
    this.total = 0
    this.searchFilters = { ...defaultFilters }
    this.specialistData = []
    this.specialistIds = []
    this.open = false
    this.modalParams = {}
    this.fetch()
    this.fetchActionPIC()
  }
  get totalRemittanceAmout() {
    let amount = 0

    if (this.specialistIds && this.specialistIds.length > 0) {
      this.specialistIds.forEach(sId => {
        const dSeleted = this.data.find(i => i.id === sId)

        if (dSeleted) {
          amount += dSeleted.commissionAmountWithTax
        }
      })
    }

    return formatMoney(amount)
  }

  filters(customFilters = {}) {
    try {
      const params: any = mapParamsFetchData({ ...this.searchFilters, ...customFilters })
      if (params.requestDateFrom) {
        params.requestDateFrom = appStore.convertFilterTimeToUTC(params.requestDateFrom, 'start')
      }
      if (params.requestDateTo) {
        params.requestDateTo = appStore.convertFilterTimeToUTC(params.requestDateTo, 'end')
      }
      if (params.actionRequiredDueDateFrom) {
        params.actionRequiredDueDateFrom = appStore.convertFilterTimeToUTC(params.actionRequiredDueDateFrom, 'start')
      }
      if (params.actionRequiredDueDateTo) {
        params.actionRequiredDueDateTo = appStore.convertFilterTimeToUTC(params.actionRequiredDueDateTo, 'end')
      }
      const date = dayjs(`${params.monthPeriod}${params.yearPeriod}`, 'MMMMYYYY')
      params.paymentPeriod = date.startOf('month').format('YYYY-MM-DD')

      // delete params.monthPeriod
      // delete params.yearPeriod
      return params
    } catch (e) {
      this.loading = false
    }
  }

  changeFiltersValue = (obj, refresh = true) => {
    let reset = refresh

    Object.keys(obj).forEach(key => {
      this.searchFilters[key] = obj[key]
      if (key === 'monthPeriod' || key === 'yearPeriod') {
        //case choose both monthPeriod & yearPeriod
        if (this.searchFilters.monthPeriod && this.searchFilters.yearPeriod) {
          const date = dayjs(`${this.searchFilters.monthPeriod}${this.searchFilters.yearPeriod}`, 'MMMMYYYY')
          this.searchFilters.requestDateFrom = date.startOf('month').format('YYYY-MM-DD')
          this.searchFilters.requestDateTo = date.endOf('month').format('YYYY-MM-DD')
        }
        //case choose only yearPeriod
        else if (this.searchFilters.yearPeriod) {
          const date = dayjs(`${this.searchFilters.yearPeriod}`, 'YYYY')
          this.searchFilters.requestDateFrom = date.startOf('year').format('YYYY-MM-DD')
          this.searchFilters.requestDateTo = date.endOf('year').format('YYYY-MM-DD')
        }
        //case choose only monthPeriod
        else {
          this.searchFilters.requestDateFrom = null
          this.searchFilters.requestDateTo = null
        }
      }

      if (key === 'skipCount') reset = false
    })

    if (reset) {
      this.searchFilters.skipCount = 0
      this.specialistIds = []
    }

    // if (refresh) {
    //   this.fetch()
    // }
  }

  setFieldsValue = obj => {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key]
    })
  }

  fetchSpecialist = (filterText?) => {
    ApiService.Specialist.GetAll({
      filterText,
      maxResultCount: 999,
      isActive: true,
      isSelectionList: true,
    }).then(res => {
      this.specialistData = res?.data?.items
    })
  }

  fetch = () => {
    this.loading = true
    ApiService.Specialist.SpecialistWithPayment(this.filters())
      .then(res => {
        this.data = res.data?.items || []
        this.total = res.data?.totalCount || 0
      })
      .finally(
        action(() => {
          this.loading = false
        }),
      )
  }
  fetchActionPIC = () => {
    ApiService.ActionRequired.GetAssignedUsersCount({ fromModule: 'SPECIALIST' }).then(
      action(({ data }) => {
        this.dataActionPIC = data
      }),
    )
  }
  toggleModal = (isOpen, params = {}) => {
    action(() => {
      this.open = isOpen
      this.modalParams = params
    })()
  }

  toggleModalViewCheckedStatement = (isOpen, params = {}) => {
    action(() => {
      this.openModalViewCheckedStatement = isOpen
      this.modalParams = params
    })()
  }

  export = (customFilters = {}, specialistName = 'Specialist') => {
    return new Promise((resolve, reject) => {
      ApiService.Specialist.ExportSpecialistWithPayment(this.filters(customFilters))
        .then(res => {
          downloadFileFromResponse(res, `${specialistName} - Remittance.xlsx`)
          resolve(null)
        })
        .catch(err => reject(err))
    })
  }

  exportRemittanceList = (customFilters = {}) => {
    return appStore.openConfirmModal({
      message: 'Do you want to export search  results?',
      onOk: () => {
        this.loading = true
        ApiService.Specialist.ExportSpecialistRemittanceList(this.filters(customFilters))
          .then(res => {
            downloadFileFromResponse(res, `Specialist Remittance.xlsx`)
          })
          .catch(err => {
            showErrorForDev(err)
          })
          .finally(
            action(() => {
              this.loading = false
            }),
          )
      },
    })
  }

  print = (customFilters = {}) => {
    return new Promise((resolve, reject) => {
      ApiService.Specialist.PrintSpecialistWithPayment(this.filters(customFilters))
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }

  sendCommissionRemittanceStatementToSpecialist = () => {
    const params = this.filters()

    return ApiService.Specialist.SendCommissionRemittanceStatementToSpecialist(this.specialistIds, {
      invoiceDateFrom: params.requestDateFrom,
      invoiceDateTo: params.requestDateTo,
      paymentPeriod: params.paymentPeriod,
      showProviderPayment: params.showProviderPayment,
    })
  }

  handleView = specialistId => {
    const params = this.filters({ specialistId: specialistId })
    const queryString = new URLSearchParams(params).toString()
    window.open(`/view/specialist-remittance/print?${queryString}`, '_blank')
  }

  handleViewCheckedSpecialist = () => {
    this.specialistIds.forEach(specialistId => {
      this.handleView(specialistId)
    })
  }
}

export default new Store()
