import './index.css'
import './styles/main.scss'
import './translator'
import { createBrowserHistory } from 'history'
import { configure } from 'mobx'
import { syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import App from './App'
import router from './stores/router'
import * as bugTracking from './tracking/bugTracking'
import { queryClient } from '@services/baseService'
import { initTranslator } from './translator'
import { observer } from 'mobx-react'
import appStore from '@stores/appStore'
import { QueryClientProvider } from 'react-query'
import { createRoot } from 'react-dom/client'
import loadable from '@loadable/component'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import minMax from 'dayjs/plugin/minMax'
import isBetween from 'dayjs/plugin/isBetween'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import relativeTime from 'dayjs/plugin/relativeTime'

import { ConfigProvider } from 'antd'
import TelemetryProvider from 'tracking/applicationInsight/telemetry-provider'
import { getAppInsights } from 'tracking/applicationInsight/telemetryService'
import EmailTemplatePreview from '@components/EmailTemplatePreview'
import PageModal from '@components/PageModal'
import ModalConfirm from '@components/AppModal/ModalConfirm'
import WarningExitWithoutSave from '@components/AppModal/WarningExitWithoutSave'
import { initAppConsole } from '@utils/appConsole'
import ThemeSettings from '@components/ThemeSettings'
import RingCentral from '@components/RingCentral'
const QuickSetting = loadable(() => import('@components/QuickSetting'))

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(minMax)
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(relativeTime)

initAppConsole()

configure({ enforceActions: 'never' })

window.APP_CONFIG = {
  maxResultCount: 20,
}
const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, router)

let appInsights = null

const RootComponent = observer(() => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1fc0da',
          fontFamily: 'Nunito, Helvetica, sans-serif',
        },
      }}
    >
      <Router history={history}>
        <TelemetryProvider
          instrumentationKey={process.env.VITE_APPINSIGHT_INSTRUMENTATION_KEY}
          after={() => {
            appInsights = getAppInsights()
            appStore.appInsights = appInsights
          }}
          history={history}
        >
          <QueryClientProvider client={queryClient}>
            <App />
            {appStore.isOpenConfirmModal && (
              <ModalConfirm modalParams={appStore.confirmModalParams} onCancel={() => appStore.closeConfirmModal()} />
            )}
            {appStore.showThemeSetting && <ThemeSettings />}
            {appStore.isOpenWarningWithoutSave && (
              <WarningExitWithoutSave
                modalParams={appStore.warningWithoutSaveParams}
                onCancel={() => appStore.closeWarningExitWithoutSave()}
              />
            )}

            <PageModal />
            <RingCentral />
            {import.meta.env.DEV && <QuickSetting />}

            {appStore.previewEmailTemplateData.isOpen && <EmailTemplatePreview />}
          </QueryClientProvider>
        </TelemetryProvider>
      </Router>
    </ConfigProvider>
  )
})

if (import.meta.env.PROD) {
  bugTracking.init()
}

initTranslator({
  language: 'MLP',
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RootComponent />)
