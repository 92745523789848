import { observer } from 'mobx-react'
import { DEFAULT_FILE_TYPE } from '@utils/constants'
import { AppFormFileUpload } from '@components/AppComponents'

export enum AttachmentExtensionEnum {
  CLEAN_UP_FILE = 'CLEAN_UP_FILE',
  SUMMARY = 'SUMMARY',
}
export interface UploadComponentProps {
  attachments?: any[]
  attachmentFrom?: string
  attachmentType: string
  objectId: string
  onAdd?: (attachments: any[]) => void
  onChange?: (attachments: any[]) => void
  multiUpload?: boolean
  name?: string
  onRemove?: (ids: string[]) => void
  disabled?: boolean
  label?: string | React.ReactNode
  labelCol?: any
  wrapperCol?: any
  classNameUpload?: string
  type?: string[]
  maxSize?: number
  disabledDelete?: boolean
  tenantId?: string
  tooltipMessage?: string
  suffixLabel?: string | React.ReactNode
  functionCallback?: () => void
  extensions?: AttachmentExtensionEnum[]
}

const UploadComponent = ({
  attachments = [],
  attachmentFrom = '',
  attachmentType,
  objectId,
  onAdd = attachments => {},
  onChange = attachments => {},
  multiUpload,
  name,
  onRemove = ids => {},
  disabled,
  label,
  labelCol,
  wrapperCol,
  classNameUpload,
  type = null,
  maxSize,
  disabledDelete,
  tenantId,
  tooltipMessage,
  suffixLabel,
  functionCallback,
  extensions = [],
}: UploadComponentProps) => {
  const handleAfterUpload = fileList => {
    const allAttachments = multiUpload
      ? attachments || []
      : (attachments || []).filter(i => i.attachmentType !== attachmentType)

    const newAttachments = fileList.map(i => ({
      ...i,
      objectId,
      attachmentType,
      attachmentFrom,
    }))

    onAdd(newAttachments)
    onChange([...allAttachments, ...newAttachments])
    if (functionCallback) {
      functionCallback()
    }
  }

  const handleFileAttachChange = (_, file) => {
    if (file.status === 'removed') {
      handleRemoveFile(file.fileId)
      return
    }

    handleAfterUpload([file])
  }

  const handleRemoveAll = () => {
    onRemove(attachments.map(i => i.fileId))
    onChange([])
  }

  const handleRemoveFile = fileId => {
    const newAttachments = attachments.filter(item => item.fileId !== fileId)
    onRemove([fileId])
    onChange(newAttachments)
  }

  return (
    <AppFormFileUpload
      name={name}
      multiUpload={multiUpload}
      tooltipMessage={tooltipMessage}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      label={label}
      suffixLabel={suffixLabel}
      type={type || DEFAULT_FILE_TYPE}
      fileList={attachments}
      onChange={handleFileAttachChange}
      onRemoveAll={handleRemoveAll}
      disabled={disabled}
      classNameUpload={classNameUpload}
      maxSize={maxSize}
      disabledDelete={disabled || disabledDelete}
      tenantId={tenantId}
      extensions={extensions}
    />
  )
}

export default observer(UploadComponent)
