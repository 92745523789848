import { DownloadOutlined, MoreOutlined, OpenAIOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import AppModal from '@components/AppModal'
import ModalConfirm from '@components/AppModal/ModalConfirm'
import ButtonLink from '@components/ButtonLink'
import { Flex } from '@components/Layout'
import TooltipInfo from '@components/TooltipInfo'
import { ApiService } from '@services/ApiService'
import appStore from '@stores/appStore'
import { Dropdown, Form, Spin } from 'antd'
import { FormLabelAlign } from 'antd/lib/form/interface'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { AppButton } from '../AppButton'
import { AppFileUpload } from '../AppFileUpload'
import './style.scss'
import { AttachmentExtensionEnum } from '@components/CaseFieldUpload/Upload'
import { ReactComponent as CheckDuplicateIcon } from '@assets/svg/check-duplicate.svg'
import CleanupSummaryModal from './components/CleanupSummaryModal'
import ButtonText from '../ButtonText'

interface AppFormFileUploadProps {
  className?: string
  classNameUpload?: string
  label?: string | React.ReactNode
  suffixLabel?: string | React.ReactNode
  rules?: any
  validateStatus?: any
  help?: string | React.ReactNode
  required?: boolean
  labelAlign?: string
  labelCol?: any
  wrapperCol?: any
  dragMessages?: string
  name?: string
  type?: string[]
  description?: string
  multiUpload?: boolean
  maxSize?: number
  previewTemplate?: any
  fileList?: any[]
  disabled?: boolean
  attachmentTypeId?: string
  isViewMergeFile?: boolean
  onChange?: (files: any[], file: any) => void
  beforeUpload?: () => void
  disabledDelete?: boolean
  tenantId?: string
  tooltipMessage?: string
  onRemoveAll?: () => void
  extensions?: AttachmentExtensionEnum[]
}
export const AppFormFileUpload = ({
  className = '',
  classNameUpload = '',
  label,
  suffixLabel,
  rules = null,
  validateStatus = null,
  help = null,
  required = false,
  labelAlign = 'left',
  labelCol,
  wrapperCol,
  dragMessages = 'Drag and drop here or Click to browse',
  name = 'file',
  type = [],
  description = null,
  multiUpload = false,
  maxSize = 100,
  previewTemplate = null,
  fileList = [],
  disabled = false,
  attachmentTypeId = null,
  isViewMergeFile = false,
  onChange = (files, file) => {},
  beforeUpload = () => {},
  disabledDelete = false,
  tenantId = null,
  tooltipMessage = '',
  onRemoveAll = () => {},
  extensions = [],
}: AppFormFileUploadProps) => {
  const [attachments, setAttachments] = useState([])
  const [isOpenAttachmentList, setIsOpenAttachmentList] = useState(false)
  const [key, setKey] = useState(Date.now())
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [currentFileIndex, setCurrentFileIndex] = useState(null)
  const [isOpenSummary, setIsOpenSummary] = useState(false)
  const [summaryTitle, setSummaryTitle] = useState('')
  const [summaryText, setSummaryText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isPdfFile = fileName => {
    if (!fileName) return false
    return fileName.toLowerCase().endsWith('.pdf')
  }
  const handleOnChange = (files, file) => {
    setAttachments(files)
    onChange(files, file)
  }

  const beforeUpload_ = () => {
    if (!!attachments.length && !multiUpload) {
      handleRemoveFile(0)
    }
    beforeUpload && beforeUpload()
  }

  const handleRemoveFile = index => {
    const newAttachments = [...attachments]
    const file = newAttachments.splice(index, 1)
    setAttachments([...newAttachments])
    onChange(newAttachments, { ...file[0], status: 'removed' })
    if (newAttachments.length === 0) {
      setIsOpenAttachmentList(false)
    }
  }

  const renderTitle = () => {
    return fileList && !!fileList.length
      ? `(${fileList.length} ${fileList.length > 1 ? 'files' : 'file'})`
      : !isViewMergeFile
      ? disabled
        ? '(No file)'
        : ``
      : '(No Merge File)'
  }

  useEffect(() => {
    setAttachments(fileList)
  }, [fileList])

  const handleDownloadFile = file => {
    ApiService.File.Download(file)
  }

  const handleRemoveAllFiles = () => {
    appStore.openConfirmModal({
      message: 'Do you want delete all files?',
      onOk: () => {
        onRemoveAll()
      },
    })
  }

  const handleSummaryDocument = async attachment => {
    if (!attachment.summaryText) {
      setIsLoading(true)
      try {
        const res = await ApiService.Attachment.SummaryDocument(attachment.id)
        attachment.summaryText = res.data
      } finally {
        setIsLoading(false)
      }
    }

    setIsOpenSummary(true)
    setSummaryText(attachment.summaryText)
    setSummaryTitle(attachment.name)
  }

  const [cleanupSummaryData, setCleanupSummaryData] = useState(null)
  const [isOpenCleanupSummary, setIsOpenCleanupSummary] = useState(false)
  const handleCleanupFile = async attachmentId => {
    setIsLoading(true)

    try {
      const resp = await ApiService.Attachment.CleanupFile(attachmentId)
      const cleanupSummary = resp.data

      setIsOpenCleanupSummary(true)
      setCleanupSummaryData(cleanupSummary)

      if (resp.data) {
        appStore.showSuccessMessage.customMessage('File has been cleaned up successfully')
      } else {
        appStore.showSuccessMessage.customMessage('File already cleaned up. No action required')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const summaryEnabled = extensions?.includes(AttachmentExtensionEnum.SUMMARY)
  const cleanupFileEnabled = extensions?.includes(AttachmentExtensionEnum.CLEAN_UP_FILE)

  return (
    <Form.Item
      className={cx([className, 'form-file-upload'])}
      label={''}
      rules={rules}
      validateStatus={validateStatus}
      help={help}
      required={required}
      labelAlign={labelAlign as FormLabelAlign}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <div className={cx(['flex justify-between mb-3', classNameUpload])}>
        <span className="mt-2 w-full lg:w-auto flex items-center gap-2">
          <span>{label}</span>
          {renderTitle()}
          {tooltipMessage ? (
            <TooltipInfo text={tooltipMessage} />
          ) : (
            !multiUpload && <TooltipInfo text={'Only 1 document is allowed'} />
          )}
          {suffixLabel}
        </span>

        <Flex>
          {summaryEnabled && (
            <OpenAIOutlined title="Document summary is enabled" className="hover:text-blue-500 text-xl" />
          )}
          <AppButton
            className="rounded-md"
            disabled={!fileList.length}
            color="blue"
            onClick={() => setIsOpenAttachmentList(true)}
            title="View all"
            size="small"
          >
            <EyeOutlined />
          </AppButton>
          <AppButton
            className="rounded-md"
            disabled={!fileList.length || appStore.isDemoAccount}
            color="green"
            onClick={() => ApiService.File.DownloadAll(fileList)}
            title="Download all"
            size="small"
          >
            <DownloadOutlined width="16" height="16" />
          </AppButton>
        </Flex>
        <AppModal
          open={isOpenAttachmentList}
          width={768}
          onCancel={() => setIsOpenAttachmentList(false)}
          footer={
            <AppButton color="red" outline onClick={() => setIsOpenAttachmentList(false)}>
              Close
            </AppButton>
          }
          title={
            <Flex className="justify-between">
              <span>{renderTitle()}</span>
              {!disabledDelete && attachments.length > 0 && (
                <ButtonLink color="red" className="text-base" onClick={() => handleRemoveAllFiles()}>
                  Remove All
                </ButtonLink>
              )}
            </Flex>
          }
          className="view-attachment-list-modal"
        >
          <Spin spinning={isLoading}>
            <div className="py-2.5">
              {attachments.map((file, index) => {
                return (
                  <div
                    className="flex items-center justify-between rounded p-1 bg-[#F3F5F7] px-2 file-item-container"
                    key={index}
                  >
                    <div className="font-normal flex gap-1 items-center">
                      {file.isCleanup && (
                        <span title={'The file has been cleaned up.'} className="mr-1">
                          <CheckDuplicateIcon style={{ width: '16px', height: '16px', display: 'inline-block' }} />
                        </span>
                      )}
                      {file.name || file.fileName}
                      {file.numberOfPages
                        ? ` - ${file.numberOfPages} ${file.numberOfPages > 1 ? 'pages' : 'page'}`
                        : ''}
                      {file.creationTime ? ` - ${appStore.formatDateTime(file.creationTime)}` : ''}
                    </div>

                    {!appStore.isDemoAccount && (
                      <div className="flex gap-0 item-actions items-center">
                        {summaryEnabled && isPdfFile(file.fileName) && (
                          <ButtonText onClick={() => handleSummaryDocument(file)} title="View Summary">
                            <OpenAIOutlined style={{ width: `16px` }} />
                          </ButtonText>
                        )}
                        {cleanupFileEnabled && !file.isCleanup && file.id && (
                          <ButtonText
                            onClick={() => {
                              handleCleanupFile(file.id)
                            }}
                            title="Clean up the file. Remove duplicates and blank pages."
                          >
                            <CheckDuplicateIcon style={{ width: '16px', height: '16px' }} />
                          </ButtonText>
                        )}
                        <ButtonText onClick={() => handleDownloadFile(file)} title="Download">
                          <DownloadOutlined />
                        </ButtonText>
                        {!disabledDelete && (
                          <ButtonText
                            onClick={() => {
                              setCurrentFileIndex(index)
                              setConfirmDelete(true)
                            }}
                            title="Delete"
                          >
                            <DeleteOutlined style={{ fill: 'red' }} />
                          </ButtonText>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </Spin>
        </AppModal>
      </div>
      {!disabled && (
        <AppFileUpload
          key={key}
          name={name}
          readonly={disabled || appStore.isDemoAccount}
          type={type}
          description={description ?? `(Max ${maxSize}MB)`}
          previewTemplate={previewTemplate}
          maxSize={maxSize}
          title={dragMessages}
          multiUpload={multiUpload}
          fileList={attachments}
          itemRender={() => <></>}
          attachmentTypeId={attachmentTypeId}
          onChange={handleOnChange}
          beforeUpload={beforeUpload_}
          tenantId={tenantId}
        />
      )}
      {confirmDelete && (
        <ModalConfirm
          modalParams={{
            message: 'Do you want to delete this file?',
            onOk: () => {
              if (currentFileIndex >= 0) {
                handleRemoveFile(currentFileIndex)
              }
            },
          }}
          onCancel={() => setConfirmDelete(false)}
        />
      )}

      <AppModal
        open={isOpenSummary}
        onCancel={() => setIsOpenSummary(false)}
        width={1024}
        title={
          <span>
            File summary: <span>{summaryTitle}</span>
          </span>
        }
      >
        <ReactMarkdown className="markdown">{summaryText}</ReactMarkdown>
        <div className="rounded-lg bg-grey-100 mt-2 p-2 border border-solid border-grey-400">
          <span className="font-bold">Disclaimer:</span> Contents are generated by OpenAI based on stored documents.
          Kawaconn did not create and is not responsible for this summary.
        </div>
      </AppModal>

      {isOpenCleanupSummary && (
        <CleanupSummaryModal
          cleanupSummary={cleanupSummaryData}
          onClose={() => {
            setIsOpenCleanupSummary(false)
          }}
        />
      )}
    </Form.Item>
  )
}

export default observer(AppFormFileUpload)
