import { ApiRequest } from '../baseService'

const BaseRoute = 'app/email-preview'

function previewEmailIME(data) {
  return ApiRequest.post(`${BaseRoute}/assessment`, data)
}

function previewEmailOTQUOTATION(data) {
  return ApiRequest.post(`${BaseRoute}/otquotation`, data)
}

function previewEmailMN(data) {
  return ApiRequest.post(`${BaseRoute}/medneg`, data)
}

function previewEmailSUPP(data) {
  return ApiRequest.post(`${BaseRoute}/supplementary`, data)
}

function previewEmailMVA(data) {
  return ApiRequest.post(`${BaseRoute}/mva`, data)
}

function previewEmailMR(data) {
  return ApiRequest.post(`${BaseRoute}/medicalrecord`, data)
}

function previewEmailRepaymentInvoice(data) {
  return ApiRequest.post(`${BaseRoute}/repaymentInvoice`, data)
}
function previewEmailDisbursementFunding(data) {
  return ApiRequest.post(`${BaseRoute}/disbursementFunding`, data)
}

function sendEmailIME(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/assessment?sendToEmail=${emailAddress}`, data)
}

function sendEmailOTQUOTATION(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/otquotation?sendToEmail=${emailAddress}`, data)
}

function sendEmailMN(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/medneg?sendToEmail=${emailAddress}`, data)
}

function sendEmailSUPP(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/supplementary?sendToEmail=${emailAddress}`, data)
}

function sendEmailMR(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/medicalrecord?sendToEmail=${emailAddress}`, data)
}

function sendEmailMVA(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/mva?sendToEmail=${emailAddress}`, data)
}
function sendEmailRepaymentInvoice(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/repaymentInvoice?sendToEmail=${emailAddress}`, data)
}
function sendEmailDisbursementFunding(emailAddress: string, data) {
  return ApiRequest.post(`${BaseRoute}/disbursementFunding?sendToEmail=${emailAddress}`, data)
}
const API = {
  previewEmailIME,
  previewEmailOTQUOTATION,
  previewEmailMN,
  previewEmailSUPP,
  previewEmailMVA,
  sendEmailIME,
  sendEmailOTQUOTATION,
  sendEmailMN,
  sendEmailSUPP,
  sendEmailMVA,
  sendEmailMR,
  previewEmailMR,
  sendEmailRepaymentInvoice,
  previewEmailRepaymentInvoice,
  sendEmailDisbursementFunding,
  previewEmailDisbursementFunding
}

export default API
