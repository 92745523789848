import { CheckOutlined, ExportOutlined, FilterOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons'
import dashboardStore from '@stores/dashboard'
import { Button, Dropdown, MenuProps, Switch, Tooltip } from 'antd'
import { observer } from 'mobx-react'
import { useState } from 'react'
import store from '../../store'
import NotificationTypeFilter from './NotificationTypeFilter'

const Header = () => {
  const [showUnread, setShowUnread] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const handleRefresh = async data => {
    await store.fetchNotifications(data)
    await store.getSummary()
    store.refreshDropDownContent++
  }

  const items: MenuProps['items'] = [
    {
      label: `Mark all as read`,
      key: 'mark-all-as-read',
      icon: <CheckOutlined />,
      onClick: async () => {
        await store.markAllAsRead()
      },
    },
    {
      label: `Notification setting`,
      key: 'settings',
      icon: <SettingOutlined />,
      onClick: async () => {
        setShowMenu(false)
        store.openDropdown = false
        await dashboardStore.open('/view/login-account?tab=NotificationSetting')
      },
    },
  ]

  return (
    <div className="flex justify-between pb-2 items-center">
      <h1 className="text-xl">Notifications</h1>
      <div className="flex gap-2">
        <div className="flex items-center">
          <span className="mr-2">Only show unread</span>
          <Switch
            checked={showUnread}
            onChange={value => {
              const showUnread = value == true ? true : null
              setShowUnread(value)
              handleRefresh({ showUnread: showUnread })
            }}
            size="small"
          />
        </div>

        <NotificationTypeFilter
          onChange={isOpen => {
            if (!isOpen) {
              handleRefresh({ showUnread: showUnread })
            }
          }}
        />
        {/* <div>
          <Dropdown
            dropdownRender={() => {
              return <NotificationTypeFilter />
            }}
            trigger={['click']}
            open={showFilter}
            onOpenChange={isOpen => {
              setShowFilter(isOpen)
              if (!isOpen) {
                handleRefresh({ isRead: isRead })
              }
            }}
          >
            <Button icon={<FilterOutlined />} type="text" title="Filter" />
          </Dropdown>
        </div> */}

        <div className="cursor-pointer hover:bg-grey-100 flex">
          <Tooltip title="Open in a new tab">
            <ExportOutlined
              className="text-xl"
              onClick={() => {
                dashboardStore.open('/view/notifications')
                store.setOpenCloseDropdown(false)
              }}
            />
          </Tooltip>
        </div>

        <Dropdown menu={{ items }} trigger={['click']} open={showMenu} onOpenChange={setShowMenu}>
          <Button type="text" icon={<MoreOutlined className="text-xl" />} onClick={() => setShowMenu(!showMenu)} />
        </Dropdown>
      </div>
    </div>
  )
}

export default observer(Header)
