/**
 * Customize version of console.log
 */
const appConsole = (() => {
  function log(...args) {
    // only show log in development env
    if (import.meta.env.DEV) {
      console.log(args)
    }
  }
  function error(...args) {
    // only show log in development env
    if (import.meta.env.DEV) {
      console.error(args)
    }
  }

  return {
    log,
    error,
  }
})()

export function initAppConsole() {
  window.AppConsole = appConsole
}
