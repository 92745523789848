import AuthWrapper from '@pages/auth/AuthWrapper'
import { observer } from 'mobx-react'
import DashboardContent from './MainContent'
import './MainLayout.scss'
import DashboardSidebar from './Sidebar'
import DashboardHeader from './header/DashboardHeader'
import dashboardStore from '@stores/dashboard'
import { DashboardRouteService } from 'routes-setting/dashboardRoute'
import { useLocation } from 'react-router-dom'
import RealtimeNotification from '@components/Notification/realtime-notification/RealtimeNotification'
import appStore from '@stores/appStore'

const Dashboard = () => {
  const location = useLocation()
  const currentUrl = location.pathname + location.search
  const validateCurrentRoute = () => {
    const { pathname, search } = location
    const { prevPath } = dashboardStore

    if (dashboardStore.isValidRoute(DashboardRouteService.getRouteByPathname(pathname)) === false) {
      dashboardStore.closeCurrentTab()
      window.location.href = '/'
      return
    }

    if (pathname !== prevPath && DashboardRouteService.hasPathName(pathname)) {
      dashboardStore.open(pathname + search)
    }

    const activeTab = findActiveTab()
    if (activeTab) {
      dashboardStore.setActiveTab(activeTab?.id)
    }
  }

  const findActiveTab = () => {
    const matchTabs = dashboardStore.tabs.filter(i => i.path === location.pathname)
    if (matchTabs.length > 1) {
      return matchTabs.find(i => i.absUrl == currentUrl)
    } else {
      return matchTabs[0]
    }
  }

  return (
    <AuthWrapper onReady={validateCurrentRoute}>
      <DashboardContent />
      <DashboardHeader />
      <DashboardSidebar />
      {appStore.features.RealtimeNotification && <RealtimeNotification />}
    </AuthWrapper>
  )
}

export default observer(Dashboard)
