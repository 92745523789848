import { BellOutlined } from '@ant-design/icons'
import { Badge, Dropdown, Spin } from 'antd'
import { observer } from 'mobx-react'
import ContentNotification from './Content'
import store from '../../store'
import Header from './Header'
import { useEffect } from 'react'

const NotificationDropdown = observer(() => {
  return (
    <div className="border border-grey-200 rounded-lg w-[650px] bg-white shadow-2xl p-4">
      <Header />
      <div className="h-[500px] overflow-y-auto">
        <Spin spinning={store.isLoading} size="small">
          <ContentNotification key={store.refreshDropDownContent} />
        </Spin>
      </div>
    </div>
  )
})

const NotificationSystem = observer(() => {
  useEffect(() => {
    store.getSummary()
  }, [])

  return (
    <Dropdown
      key={store.refreshCount}
      dropdownRender={() => <NotificationDropdown />}
      trigger={['click']}
      open={store.openDropdown}
      onOpenChange={store.setOpenCloseDropdown}
    >
      <div className="cursor-pointer hover:bg-blue-500 pt-3 pr-3 pl-1">
        <Badge
          offset={[-2, 5]}
          showZero
          count={store.summary.totalUnreadCount > 0 ? store.summary.totalUnreadCount : undefined}
          size="default"
          style={{ padding: 0, background: 'red', fontWeight: 'bold' }}
        >
          <BellOutlined className="text-xl text-white rounded-full p-1" />
        </Badge>
      </div>
    </Dropdown>
  )
})

export default NotificationSystem
